import * as React from "react";
import { getSlideIfPresent, getSlideIndex, makeUseContext } from "../utils.js";
import { UNKNOWN_ACTION_TYPE } from "../consts.js";
export const LightboxStateContext = React.createContext(null);
export const useLightboxState = makeUseContext("useLightboxState", "LightboxStateContext", LightboxStateContext);
export const LightboxDispatchContext = React.createContext(null);
export const useLightboxDispatch = makeUseContext("useLightboxDispatch", "LightboxDispatchContext", LightboxDispatchContext);
function reducer(state, action) {
    switch (action.type) {
        case "swipe": {
            const { slides } = state;
            const increment = (action === null || action === void 0 ? void 0 : action.increment) || 0;
            const globalIndex = state.globalIndex + increment;
            const currentIndex = getSlideIndex(globalIndex, slides.length);
            const currentSlide = getSlideIfPresent(slides, currentIndex);
            const animation = increment || action.duration
                ? {
                    increment,
                    duration: action.duration,
                    easing: action.easing,
                }
                : undefined;
            return { slides, currentIndex, globalIndex, currentSlide, animation };
        }
        case "update":
            return {
                slides: action.slides,
                currentIndex: action.index,
                globalIndex: action.index,
                currentSlide: getSlideIfPresent(action.slides, action.index),
            };
        default:
            throw new Error(UNKNOWN_ACTION_TYPE);
    }
}
export function LightboxStateProvider({ slides, index, children }) {
    const [state, dispatch] = React.useReducer(reducer, {
        slides,
        currentIndex: index,
        globalIndex: index,
        currentSlide: getSlideIfPresent(slides, index),
    });
    React.useEffect(() => {
        dispatch({ type: "update", slides, index });
    }, [slides, index]);
    const context = React.useMemo(() => ({ ...state, state, dispatch }), [state, dispatch]);
    return (React.createElement(LightboxDispatchContext.Provider, { value: dispatch },
        React.createElement(LightboxStateContext.Provider, { value: context }, children)));
}
